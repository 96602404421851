body {
    /*background-image: linear-gradient(#93B1CC, #5C7497);*/
    /*background-image: linear-gradient(rgb(54, 54, 54), rgb(34, 34, 34));*/
    color: white;
    background-color: #16171D;
    font-family: "Lato", sans-serif;
    font-weight: 100;
}

.CockPit {
    color: white;
    background-color: #16171D;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
}

::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
}

::-webkit-scrollbar-thumb {
    color: white;
    background-color: slategrey;
    opacity: 25%;
    border-radius: 9em;
}

::-webkit-scrollbar-track-piece {
    background-color: rgba(0.1, 0.1, 0.4, 0);
}

::-webkit-scrollbar-track {
}

.butt {
    background-color: #93b1cc;
    opacity: 80%;
    border: 0;
    font-size: 0.8em;
    border-radius: 0.5em;
    color: black;

    height: 2.6em;
    width: 6em;
}

.butt:hover {
    background-color: rgb(88, 88, 88);
}

.search-button {
    background-color: #93b1cc;
    opacity: 80%;
    border: 0;
    font-size: 0.8em;
    border-radius: 0.3em;
    width: 20%;
}

.search-button:hover {
    background-color: rgb(88, 88, 88);
}

.label {
}

.Input {
    width: 60%;
    height: 1.7em;
    border-radius: 0.2em;
}

@media only screen and (min-width: 2000px) {
    .Info {
        margin-block: 1em;
        width: 96%;
        display: flex;
        align-content: center;
        padding: 1.5em;
        font-weight: 100;

        background-color: #1E212E;
    }
}

@media only screen and (min-width: 300px) {
    .Info {
        width: 100%;
        margin-inline: 0;
        margin-block: 1em;
        display: flex;
        align-content: center;
        padding: 1.5em;
        font-weight: 100;

        background-color: #1E212E;
    }
}

.InfoLabel {
    font-family: "Lato", sans-serif;
    font-weight: 100;
}

.InfoInput {
    width: 8em;
}

.SideInfo {
    width: 8em;
    margin-right: 1em;
}


.tr-wl:hover {
    background-color: slategray;
}

.DetailsCardButton {
    display: flex;
    justify-content: center;
    text-align: center;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    padding-block: 1.0em;

    color: white;

    background-color: #1E212E;
}

.DetailsCardButton:hover {
    background-color: slategray;


}.DetailsCardButtonDrop {
    display: flex;
    justify-content: center;
    text-align: center;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    padding-block: 1.0em;

    color: black;

    background-color: #93b1cc;
    opacity: 75%;
}

.DetailsCardButtonDrop:hover {
    background-color: slategray;
}


td {
    color: white;
    font-family: "Lato", sans-serif;
    font-weight: 100;
}

.custom-table {
    background-color: #16171D;
}

/* Table Styles */
table {
    width: 100%;
    border-collapse: collapse;
    font-family: 'Arial', sans-serif;
    color: #ffffff;
    background-color:  #16171D;
}

thead {
    border-bottom: 2px solid #555555;
}

th, td {
    padding: 0.4em;
    border-bottom: 2px solid #ffffff;
}

/* Header */
th {
    background-color:  #16171D;
    color: #ffffff;
}

/* Body */
tbody tr {
    background-color:  #16171D;
}

/* Footer */
tfoot tr {
    background-color:  #16171D;
}

tfoot td {
    border-top: 1px solid #555555;
}

/* Zebra Striping for rows, optional */
tbody tr:nth-child(odd) {
    background-color:  #16171D;
    border-block: 0.5px solid #353535;

}
