.Access {
    height: 100vh;
    width: 100vw;
    text-align: center;
    display: flex;
    flex-direction: column;

    color: white;
    background-color: #16171D;
    font-family: "Lato", sans-serif;
    font-weight: 100;
}

.access-input {
    border-radius: 0.2em;
    border: none;
    margin-inline: 1em;
    width: 13em;
}

.access-label {
    margin-top: 1em;
    margin-left: 1em;
    text-align: left;
}
